<template>
  <div>
    <modal name="modal-confirm-add-clients" width="300" :height="`auto`">
      <v-form ref="formAddClientsInPosPage" lazy-validation>
        <v-card>
          <v-card-text>
            <v-row class="mt-4">
              <v-col cols="12" sm="12" md="12">
                <v-autocomplete
                  v-model="addCredential.visitType"
                  v-bind:label="$t('visit_type')"
                  item-text="label"
                  item-value="value"
                  :items="visitTypeList"
                  filled
                  hide-no-data
                  hide-details
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-menu
                  v-model="select_date_menu"
                  ref="date_menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="addCredential.date"
                      v-bind:label="$t('Date')"
                      prepend-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      hide-details
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="addCredential.date"
                    no-title
                    @input="select_date_menu = false"
                    :first-day-of-week="1"
                    :locale="this.$i18n.locale"
                    :reactive="reactive"
                    :min="selectable_mindate || ''"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-autocomplete
                  v-model="addCredential.brands"
                  v-bind:label="$t('brands.brand')"
                  item-text="name"
                  item-value="id"
                  :items="selectableBrands"
                  filled
                  multiple
                  hide-no-data
                  required
                  :rules="requiredRulesArray"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click.stop="onClickOK()">
              Ok
            </v-btn>
            <v-btn color="blue darken-1" text @click.stop="hideModal()">
              {{ $t("cancel") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </modal>
  </div>
</template>

<script>
export default {
  name: "ConfirmDialogAddClient",
  props: [
    "visitTypes",
    "confirmSelectionCriteria",
    "routeId",
    "posId",
    "selectableBrands"
  ],
  components: {},
  data: function() {
    return {
      select_date_menu: false,
      reactive: false,
      visitTypeList: [],
      selectable_mindate: new Date().toISOString().slice(0, 10),

      addCredential: {
        date: new Date().toISOString().slice(0, 10),
        visitType: "PRESENT",
        brands: []
      },
      requiredRulesArray: [v => (!!v && v.length > 0) || "Required"]
    };
  },
  watch: {},
  computed: {},
  methods: {
    async showModal() {
      await this.init();
      this.$modal.show("modal-confirm-add-clients");
    },
    hideModal() {
      this.$modal.hide("modal-confirm-add-clients");
    },
    onClickOK() {
      if (this.$refs.formAddClientsInPosPage.validate()) {
        this.hideModal();
        this.confirmSelectionCriteria(this.addCredential);
      }
    },

    async init() {
      this.visitTypeList = [];
      for (const [key, value] of Object.entries(this.visitTypes)) {
        let pushItem = { label: value, value: key };
        this.visitTypeList.push(pushItem);
      }
      this.addCredential = {
        date: new Date().toISOString().slice(0, 10),
        visitType: "PRESENT",
        brands: []
      };
    }
  },
  async updated() {},
  async mounted() {}
};
</script>

<style></style>
