<template>
  <div>
    <modal
      name="modal-reporting-reasons-changedvisitTypes"
      :width="`90%`"
      :height="`auto`"
      scrollable
    >
      <v-card>
        <v-card-text class="objective_content">
          <span class="text-h5">POS Tipo Visita Diferente a la Original</span>
        </v-card-text>

        <v-card-text>
          <v-form
            ref="formReasonsChangedVisitTypes"
            v-model="valid"
            lazy-validation
          >
            <b-alert show variant="info" v-if="isLoading">
              <b-icon
                icon="arrow-counterclockwise"
                animation="spin-reverse"
              ></b-icon>
              cargando datos...
            </b-alert>
            <b-alert show variant="warning" v-else-if="tableData.length === 0">
              ¡Datos vacíos!...
            </b-alert>
            <v-data-table
              v-else
              :headers="columns"
              :items="tableData"
              :options.sync="options"
              class="elevation-1"
              :hide-default-footer="true"
            >
              <template
                v-for="(col, i) in filters"
                v-slot:[`header.${i}`]="{ header }"
              >
                <div
                  style="display: inline-block; padding: 16px 0;"
                  v-bind:key="i"
                >
                  <span>{{ header.text }}</span>
                </div>
                <div style="float: right; margin-top: 8px" v-bind:key="`A` + i">
                  <v-menu
                    :close-on-content-click="false"
                    :nudge-width="200"
                    offset-y
                    transition="slide-y-transition"
                    left
                    fixed
                    style="position: absolute; right: 0"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="indigo" icon v-bind="attrs" v-on="on">
                        <v-icon
                          small
                          :color="
                            activeFilters[header.value] &&
                            activeFilters[header.value].length <
                              filters[header.value].length
                              ? 'red'
                              : 'default'
                          "
                        >
                          mdi-filter-variant
                        </v-icon>
                      </v-btn>
                    </template>
                    <v-list flat dense class="pa-0">
                      <v-list-item-group
                        multiple
                        v-model="activeFilters[header.value]"
                        class="py-2"
                      >
                        <div class="search_input">
                          <v-text-field
                            hide-details
                            v-model="searchString[header.value]"
                            placeholder="Search ..."
                            class="pl-2 pr-2"
                            @input="searchItem(header.value)"
                          ></v-text-field>
                        </div>
                        <template v-for="(item, j) in filters[header.value]">
                          <v-list-item
                            v-bind:key="`A${j}`"
                            :value="item"
                            :ripple="false"
                          >
                            <template v-slot:default="{ active, toggle }">
                              <v-list-item-action>
                                <v-checkbox
                                  :input-value="active"
                                  :true-value="item"
                                  @click="toggle"
                                  color="primary"
                                  :ripple="false"
                                  dense
                                ></v-checkbox>
                              </v-list-item-action>
                              <v-list-item-content>
                                <v-list-item-title
                                  v-text="item"
                                ></v-list-item-title>
                              </v-list-item-content>
                            </template>
                          </v-list-item>
                        </template>
                      </v-list-item-group>
                      <v-row no-gutters>
                        <v-col cols="6">
                          <v-btn
                            text
                            block
                            @click="toggleAll(header.value)"
                            color="success"
                            >{{ $t("toggleall") }}</v-btn
                          >
                        </v-col>
                        <v-col cols="6">
                          <v-btn
                            text
                            block
                            @click="clearAll(header.value)"
                            color="warning"
                            >{{ $t("clearall") }}</v-btn
                          >
                        </v-col>
                      </v-row>
                    </v-list>
                  </v-menu>
                </div>
              </template>
              <template v-slot:item.reasonTypeLabel="{ item }">
                <v-autocomplete
                  v-model="item.reasonType"
                  :items="reasonChangedVisitTypes"
                  item-text="label"
                  item-value="value"
                  filled
                  dense
                  required
                  :rules="requiredRules"
                ></v-autocomplete>
              </template>
              <template v-slot:item.reasonComments="{ item }">
                <v-text-field
                  v-model="item.reasonComments"
                  dense
                  required
                  :rules="requiredRules"
                ></v-text-field>
              </template>
            </v-data-table>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click.stop="hideModal()">
            {{ $t("cancel") }}
          </v-btn>
          <v-btn color="primary" @click="onSaveClick" :loading="isSaving">
            {{ this.tableData.length > 0 ? $t("save") : $t("Next") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </modal>
  </div>
</template>

<script>
import ApiService from "@/services/apiService";
import { logInfo, logError } from "@/utils";
import _ from "lodash";
import moment from "moment";

export default {
  name: "DialogReportReasonsForChangedVisitTypes",
  props: ["session", "meta", "mainRoute"],
  components: {},
  data: function() {
    return {
      isLoading: false,
      isSaving: false,
      today: new Date().toISOString().slice(0, 10),
      reasonChangedVisitTypes: [],
      reasons_changed_visit_types: [],

      options: {},
      tempdata: [],
      tableData: [],
      activeFilters: {},
      filters: {},
      initFilterList: {},
      searchString: {},
      valid: true,
      requiredRules: [(v) => !!v || "Required"],
    };
  },
  watch: {
    options: {
      handler() {
        this.sortChange();
      },
      deep: true,
    },
  },
  computed: {
    columns() {
      return [
        {
          text: `POS ${this.$t("brands.name")}`,
          value: "posName",
          width: "250px",
          filter: (value) => {
            return this.activeFilters.posName
              ? this.activeFilters.posName.includes(value)
              : true;
          },
        },
        {
          text: `${this.$t("chain")} ${this.$t("brands.name")}`,
          value: "chainName",
          width: "250px",
          filter: (value) => {
            return this.activeFilters.chainName
              ? this.activeFilters.chainName.includes(value)
              : true;
          },
        },
        {
          text: `POS ${this.$t("address")}`,
          value: "posFullAddress",
          width: "300px",
          filter: (value) => {
            return this.activeFilters.posFullAddress
              ? this.activeFilters.posFullAddress.includes(value)
              : true;
          },
        },
        {
          text: `original - ${this.$t("visit_type")}`,
          value: "originalVisitTypeLabel",
          width: "200px",
          filter: (value) => {
            return this.activeFilters.originalVisitTypeLabel
              ? this.activeFilters.originalVisitTypeLabel.includes(value)
              : true;
          },
        },
        {
          text: `real - ${this.$t("visit_type")}`,
          value: "realVisitTypeLabel",
          width: "200px",
          filter: (value) => {
            return this.activeFilters.realVisitTypeLabel
              ? this.activeFilters.realVisitTypeLabel.includes(value)
              : true;
          },
        },
        {
          text: this.$t("Reason for Difference"),
          value: "reasonTypeLabel",
          width: "220px",
          sortable: false,
        },
        {
          text: this.$t("comments"),
          value: "reasonComments",
          width: "200px",
          sortable: false,
        },
      ];
    },
  },
  methods: {
    async showModal() {
      this.isLoading = true;
      this.$modal.show("modal-reporting-reasons-changedvisitTypes");
      await this.init();
    },
    hideModal() {
      this.$modal.hide("modal-reporting-reasons-changedvisitTypes");
    },
    initFilters() {
      let keys = Object.keys(this.initFilterList);
      for (const key of keys) {
        this.searchString[key] = "";
        this.initFilterList[key] = this.tableData
          .map((d) => {
            return d[key];
          })
          .filter((value, index, self) => {
            return self.indexOf(value) === index;
          });
      }
      this.filters = Object.assign({}, this.initFilterList);
      this.activeFilters = Object.assign({}, this.filters);
    },
    searchItem(col) {
      this.changeStr(col, this.searchString[col]);
    },
    changeStr(col, search_str) {
      if (search_str) {
        this.filters[col] = this.initFilterList[col].filter((item) =>
          item.toLowerCase().includes(search_str.toLowerCase())
        );
      } else {
        this.filters[col] = this.initFilterList[col];
      }
      this.activeFilters = Object.assign({}, this.filters);
    },
    toggleAll(col) {
      this.activeFilters[col] = this.tableData
        .map((d) => {
          return d[col];
        })
        .filter((value, index, self) => {
          return self.indexOf(value) === index;
        });
      this.searchString[col] = null;
      this.changeStr(col, null);
    },
    clearAll(col) {
      this.activeFilters[col] = [];
    },
    sortChange() {
      let { sortBy, sortDesc } = this.options;
      if (sortBy && sortBy.length > 0) {
        sortBy = sortBy[0];
        sortDesc = sortDesc[0];
        if (sortDesc) {
          this.tempdata = _.sortBy(this.tempdata, sortBy).reverse();
        } else {
          this.tempdata = _.sortBy(this.tempdata, sortBy);
        }
        this.tableData = this.tempdata;
      }
    },
    async onSaveClick() {
      if (!this.$refs.formReasonsChangedVisitTypes.validate()) {
        logError("You should answer all rows.");
        return;
      }
      if (this.tableData.length > 0) {
        for (const row of this.tableData) {
          if (!row.reasonType) {
            logError("You should answer all rows.");
            return;
          }
        }
        this.isSaving = true;
        // Save answers
        let formData = {
          reasons_changed_visit_types: this.tableData,
        };
        const response = await ApiService.post(
          `worksession/report_save_changed_visit_types`,
          formData
        );
        this.isSaving = false;
        if (response && response?.success) {
          logInfo(this.$t("Success"));
        } else {
          logError(this.$t("Failed"));
          return;
        }
      }
      this.goNextStepPage();
    },
    goNextStepPage() {
      this.hideModal();
      // go to next step
      this.$emit("goToNextStep", "NEXT_STEP_KILOMETERS_ROUTE");
    },
    initializeTableData(tbData) {
      this.tableData = tbData;
      this.tempdata = tbData;
      this.initFilters();
    },
    async init() {
      let initFilterCriteria = {
        posName: [],
        chainName: [],
        posFullAddress: [],
        originalVisitTypeLabel: [],
        realVisitTypeLabel: [],
      };
      this.filters = JSON.parse(JSON.stringify(initFilterCriteria));
      this.initFilterList = JSON.parse(JSON.stringify(initFilterCriteria));
      this.searchString = JSON.parse(JSON.stringify(initFilterCriteria));
      const { id } = this.session;
      let { mergedPosData } = this.meta;
      this.isSaving = false;
      if (id) {
        if (mergedPosData && mergedPosData.length > 0) {
          mergedPosData = mergedPosData
            .filter((el) => el.changedVisitType)
            .map((el) => {
              let {
                id,
                routeId,
                posId,
                visitType,
                changedVisitType,
                brand_list,
                status,
              } = el;
              return {
                routeId,
                posId,
                visitType,
                changedVisitType,
                brand_list,
                status,
              };
            });
        } else {
          mergedPosData = [];
        }
        let form_data = {
          worksessionId: id,
          mergedPosData,
          today: this.today,
        };
        const {
          reasonChangedVisitTypes,
          reasons_changed_visit_types,
        } = await ApiService.post(
          `worksession/report_init_changed_visit_type`,
          form_data
        );
        this.reasonChangedVisitTypes = reasonChangedVisitTypes;
        this.reasons_changed_visit_types = reasons_changed_visit_types;
        if (this.reasons_changed_visit_types.length > 0) {
          this.isLoading = false;
          this.initializeTableData(this.reasons_changed_visit_types);
        } else {
          this.goNextStepPage();
        }
      }
    },
  },
  async updated() {},
  async mounted() {},
};
</script>

<style></style>
