<template>
  <div>
    <div class="" v-if="mainRoute">
      <div class="border d-flex justify-content-center align-center mb-2 py-1">
        <span class="trencadis-title">
          Mi Ruta <b> : {{ this.mainRoute.name }}</b>
        </span>
      </div>
      <v-row v-if="mainRoute">
        <v-col cols="12" xs="12" sm="6" md="4">
          <b-card header-tag="header">
            <template #header>
              <div class="d-flex justify-content-between">
                <h6 class="mb-0">Resumen Diario</h6>
                <!-- <b-badge variant="danger" class="mr-1">18</b-badge> -->
              </div>
            </template>
            <b-card-body class="dailysummarybox">
              <b-alert
                v-if="isLoading"
                variant="warning"
                class="m-0 p-2 my-alert"
                show
              >
                <i> cargando datos...</i>
              </b-alert>
              <div v-else-if="session && meta" class="d-flex flex-column">
                <div class="d-flex ">
                  Status jornada:
                  <div class="text-bold ml-1">
                    <template v-if="session.startTime">
                      <span style="color: green">
                        activa ({{
                          moment(session.startTime).format(
                            "YYYY-MM:DD HH:mm:ss"
                          )
                        }})
                      </span>
                    </template>
                    <template v-if="session.startTime && isEndedSession">
                      <br />
                      <span style="color: green">
                        finalizada ({{
                          moment(session.endTime).format("YYYY-MM:DD HH:mm:ss")
                        }})
                      </span>
                    </template>
                    <template v-if="!session.startTime">
                      <span style="color: red">inactiva</span>
                    </template>
                  </div>
                </div>
                <div v-if="isEndedSession">
                  Total Jornada:
                  <b>
                    {{ diffStartEndLocalTimeOfWorksessionLabel }}
                  </b>
                </div>
                <div v-if="meta?.routeVisitStatus">
                  Clientes Visitados:
                  <b>
                    {{ meta?.routeVisitStatus.positive }} /
                    {{ meta?.routeVisitStatus.total }}
                  </b>
                </div>
                <div v-if="meta?.routeVisitStatus">
                  Pendientes de recuperar:
                  <b>
                    {{ meta?.routeVisitStatus.visitedRecover }} /
                    {{ meta?.routeVisitStatus.totalRecover }}
                  </b>
                </div>
                <div>
                  Venta vs Plan:
                  <b>
                    {{ visitStatusSalesAndPlan.sales_value }} € /
                    {{ visitStatusSalesAndPlan.sales_plan }} €
                  </b>
                </div>
                <div>
                  Puntuación PS:
                  <b>
                    {{ visitStatusSalesAndPlan.ps_value }} /
                    {{ visitStatusSalesAndPlan.ps_plan }} Obj /
                    {{
                      visitStatusSalesAndPlan.diff_vs_last_ps > 0
                        ? `+${visitStatusSalesAndPlan.diff_vs_last_ps}`
                        : `${visitStatusSalesAndPlan.diff_vs_last_ps}`
                    }}
                    vs Ult Visita
                  </b>
                </div>
              </div>
            </b-card-body>
          </b-card>
        </v-col>
        <v-col cols="12" xs="12" sm="6" md="4">
          <b-card header-tag="header">
            <template #header>
              <div class="d-flex justify-content-between">
                <h6 class="mb-0">
                  <router-link to="/expenses">
                    Kilometraje y gastos
                  </router-link>
                </h6>
                <!-- <b-badge variant="danger" class="mr-1">10</b-badge> -->
              </div>
            </template>
            <b-card-body :style="{ minHeight: dailysummaryboxHeight + 'px' }">
              <b-alert
                v-if="isLoading"
                variant="warning"
                class="m-0 p-2 my-alert"
                show
              >
                <i> cargando datos...</i>
              </b-alert>
              <template v-else-if="current_km_data && todayExpenseOthers">
                <div>
                  {{ $t("Vehicle Type") }}:
                  <span class="expense_item_value">
                    {{ this.vehicleTypes[current_km_data?.vehicleType] }}
                  </span>
                </div>
                <div>
                  {{ $t("Start KM") }}:
                  <span class="expense_item_value">
                    {{
                      current_km_data?.startKM
                        ? numberFormatEs(current_km_data?.startKM)
                        : ""
                    }}
                  </span>
                </div>
                <div v-if="isEndedSession">
                  {{ $t("End KM") }}:
                  <span class="expense_item_value">
                    {{
                      current_km_data?.endKM
                        ? numberFormatEs(current_km_data?.endKM)
                        : ""
                    }}
                  </span>
                </div>
                <div v-if="isEndedSession">
                  {{ $t("Difference") }}:
                  <span class="expense_item_value">
                    {{ formattedCalcDifferenceStartEndKM }}
                  </span>
                </div>
                <template
                  v-if="todayExpenseOthers && todayExpenseOthers.length > 0"
                >
                  <div>
                    {{ $t("Other Expenses") }}
                  </div>
                  <div>
                    <ul class="mb-0">
                      <li
                        v-for="(expenseItem, expenseKey) in todayExpenseOthers"
                        :key="`expense_item_${expenseKey}`"
                      >
                        {{ expenseItem?.expenseTypeLabel }} :
                        <span class="expense_item_value">
                          {{ expenseItem?.formatedAmount }}
                        </span>
                      </li>
                    </ul>
                  </div>
                </template>
              </template>
            </b-card-body>
          </b-card>
        </v-col>
        <v-col cols="12" xs="12" sm="6" md="4">
          <b-card header-tag="header">
            <template #header>
              <div class="d-flex justify-content-between">
                <h6 class="mb-0">Rutero</h6>
              </div>
            </template>
            <b-card-body :style="{ minHeight: dailysummaryboxHeight + 'px' }">
              <div class="d-flex justify-content-center">
                <v-date-picker
                  v-model="picker"
                  :first-day-of-week="1"
                  :locale="this.$i18n.locale"
                  @change="onChangeDate"
                ></v-date-picker>
              </div>
            </b-card-body>
          </b-card>
        </v-col>
      </v-row>
      <b-card-group deck class="mb-3" style="font-size: 14px"> </b-card-group>

      <div
        class="d-flex justify-content-center"
        v-if="session && !isEndedSession"
      >
        <b-button
          @click="goToRoute"
          variant="primary"
          class="mr-1 btn_control_route"
          :disabled="!currentPosition"
        >
          {{
            session.startTime
              ? `${$t("session.my_route")}`
              : $t("session.start_route")
          }}
          <b-icon icon="caret-right-square"></b-icon>
        </b-button>
        <b-button
          @click="pauseAndRestartRoute(true)"
          variant="warning"
          class="mr-1 btn_control_route"
          v-if="session.startTime && !session.isPaused"
          :disabled="
            isLoadingButtons.PAUSE_AND_RESTART_SESSION || !currentPosition
          "
        >
          <b-spinner
            v-if="isLoadingButtons.PAUSE_AND_RESTART_SESSION"
            medium
          ></b-spinner>
          <template v-else>
            {{ $t("session.pause_route") }}
            <b-icon icon="caret-right-square"></b-icon>
          </template>
        </b-button>
        <b-button
          @click="pauseAndRestartRoute(false)"
          variant="danger"
          class="mr-1 btn_control_route"
          v-if="session.startTime && session.isPaused"
          :disabled="
            isLoadingButtons.PAUSE_AND_RESTART_SESSION || !currentPosition
          "
        >
          <b-spinner
            v-if="isLoadingButtons.PAUSE_AND_RESTART_SESSION"
            medium
          ></b-spinner>
          <template v-else>
            {{ $t("session.restart_route") }}
            <b-icon icon="caret-right-square"></b-icon>
          </template>
        </b-button>
        <b-button
          @click="finishRoute"
          variant="success"
          class="mr-1 btn_control_route"
          v-if="session.startTime"
          :disabled="!currentPosition"
        >
          {{ $t("session.finish_route") }}
          <b-icon icon="caret-right-square"></b-icon>
        </b-button>
      </div>
      <div
        class="d-flex justify-content-center"
        v-else-if="session && isEndedSession"
      >
        <a
          @click="onClickDownloadPDFDailySummary()"
          class="d-flex justify-content-center align-center mr-2"
          :disabled="isLoadingButtons.DOWNLOAD_PDF"
        >
          <b-spinner v-if="isLoadingButtons.DOWNLOAD_PDF" medium></b-spinner>
          <template v-else>
            <img
              class=""
              src="@/assets/download-pdf-icon.png"
              alt=""
              height="50"
            />
          </template>
        </a>
        <b-button
          @click="goToRoutePage()"
          variant="primary"
          class="mr-2 btn_control_route"
        >
          {{ $t("session.see_route") }}
          <b-icon icon="caret-right-square"></b-icon>
        </b-button>
        <b-button
          @click="restartSession()"
          variant="success"
          class="mr-2 btn_control_route"
          :disabled="isLoadingButtons.RESTART_SESSION"
        >
          <b-spinner v-if="isLoadingButtons.RESTART_SESSION" medium></b-spinner>
          <template v-else>
            {{ $t("session.restart_route") }}
            <b-icon icon="caret-right-square"></b-icon>
          </template>
        </b-button>
      </div>

      <DialogSelectedDateRoutePos
        ref="dialogSelectedDateRoutePos"
        :routeDate="picker"
        :downloadPDFDailySummary="downloadPDFDailySummary"
      />
      <DialogReportReasonsForNotVisiting
        v-if="session"
        ref="dialogReportReasonsForNotVisiting"
        :session="session"
        :meta="meta"
        :mainRoute="mainRoute"
        @goToNextStep="nextStepIndex => goToNextStep(nextStepIndex)"
      />
      <DialogReportReasonsForChangedVisitTypes
        v-if="session"
        ref="dialogReportReasonsForChangedVisitTypes"
        :session="session"
        :meta="meta"
        :mainRoute="mainRoute"
        @goToNextStep="nextStepIndex => goToNextStep(nextStepIndex)"
      />
      <DialogReportReasonsForKilometersRoute
        v-if="session"
        ref="dialogReportReasonsForKilometersRoute"
        :session="session"
        :meta="meta"
        :mainRoute="mainRoute"
        @goToNextStep="nextStepIndex => goToNextStep(nextStepIndex)"
      />
      <DialogStartKM
        v-model="showStartKMDialog"
        ref="dialogStartKM"
        :vehicleTypeList="vehicleTypeList"
        :session="session"
        :current_km_data="current_km_data"
        :mainRoute="mainRoute"
        @savingStartKM="response => savingStartKM(response)"
      />
    </div>
    <b-alert v-else variant="warning" class="m-0 p-2 my-alert" show>
      <i> No tienes ninguna ruta asignada....</i>
    </b-alert>
  </div>
</template>

<script>
import _ from "lodash";
import ApiService from "../../services/apiService";
import { mapGetters } from "vuex";
import moment from "moment";
import { logInfo, logError } from "@/utils";
import DialogSelectedDateRoutePos from "./DialogSelectedDateRoutePos.vue";
import DialogStartKM from "./DialogStartKM.vue";
import DialogReportReasonsForNotVisiting from "./finishroute/DialogReportReasonsForNotVisiting.vue";
import DialogReportReasonsForChangedVisitTypes from "./finishroute/DialogReportReasonsForChangedVisitTypes.vue";
import DialogReportReasonsForKilometersRoute from "./finishroute/DialogReportReasonsForKilometersRoute.vue";
import download from "js-file-download";

export default {
  name: "sessionDashboard",
  components: {
    DialogSelectedDateRoutePos,
    DialogStartKM,
    DialogReportReasonsForNotVisiting,
    DialogReportReasonsForChangedVisitTypes,
    DialogReportReasonsForKilometersRoute
  },
  computed: {
    ...mapGetters("auth", ["user", "isBrand"]),
    ...mapGetters("myroute", [
      "messageVisitAlert",
      "currentPosition",
      // "currentGmapAddress",
      "isEndedSession"
    ]),
    calcDifferenceStartEndKM() {
      let ret = 0;
      if (this.current_km_data) {
        const { startKM, endKM } = this.current_km_data;
        if (!isNaN(endKM - startKM)) {
          ret = endKM - startKM;
        }
      }
      return ret;
    },
    formattedCalcDifferenceStartEndKM() {
      return this.numberFormatEs(this.calcDifferenceStartEndKM);
    },
    userLiquidationType() {
      let liquidationType = "TOTAL_KM";
      if (this.user && this.user?.liquidationType) {
        liquidationType = this.user.liquidationType;
      }
      return liquidationType;
    },
    visitStatusSalesAndPlan() {
      let ret = {
        sales_value: null,
        sales_plan: null,
        ps_value: null,
        ps_plan: null,
        diff_vs_last_ps: null
      };
      if (this.meta && this.meta?.results_for_ps_sales_values) {
        const {
          sales_values,
          ps_values
        } = this.meta.results_for_ps_sales_values;
        if (sales_values && sales_values?.today) {
          for (const [key, value] of Object.entries(sales_values?.today)) {
            if (value) {
              if (!isNaN(value?.objective)) {
                ret.sales_plan += value?.objective * value.count;
              }
              if (!isNaN(value?.sales)) {
                ret.sales_value += value?.sales * value.count;
              }
            }
          }
        }

        if (ps_values && ps_values?.realToday) {
          for (const [key, value] of Object.entries(ps_values?.realToday)) {
            if (value) {
              if (!isNaN(value?.objective)) {
                // ret.ps_plan += value?.objective * value.count;
                ret.ps_plan += value?.objective;
              }
              if (!isNaN(value?.ps_value)) {
                // ret.ps_value += value?.ps_value * value.count;
                ret.ps_value += value?.ps_value;
              }
              if (!isNaN(value?.diff_vs_last)) {
                // ret.diff_vs_last_ps += value?.diff_vs_last * value.count;
                ret.diff_vs_last_ps += value?.diff_vs_last;
              }
            }
          }
        }
      }
      for (const [key, value] of Object.entries(ret)) {
        if (value === null || (!this.session.startTime && value === 0))
          ret[key] = "-";
      }
      return ret;
    }
  },
  data: function() {
    return {
      isLoading: false,
      session: null,
      meta: null,
      mainRoute: null,
      picker: new Date().toISOString().substr(0, 10),
      dialogWorksessionId: null,
      current_km_data: null,
      todayExpenseOthers: null,
      vehicleTypes: {},
      vehicleTypeList: [],
      showStartKMDialog: false,
      dailysummaryboxHeight: 0,
      totalTimeVisitedMiliseconds: 0,
      diffStartEndLocalTimeOfWorksessionLabel: null,
      timeVisitedStr: 0,
      isLoadingButtons: {
        RESTART_SESSION: false,
        PAUSE_AND_RESTART_SESSION: false,
        DOWNLOAD_PDF: false
      }
    };
  },
  methods: {
    moment,
    async savingStartKM(response) {
      this.showStartKMDialog = false;
      if (!this.session.startTime) {
        let startPosition = {
          startLat: null,
          startLng: null
        };
        let localTimeZoneOffset = new Date().getTimezoneOffset();
        let startLocalTime = moment().format("YYYY-MM-DD HH:mm:ss");
        if (this.currentPosition) {
          startPosition.startLat = this.currentPosition.latitude;
          startPosition.startLng = this.currentPosition.longitude;
        }
        await ApiService.post(`worksession/${this.session.id}/start`, {
          startPosition,
          startLocalTime,
          localTimeZoneOffset,
          mainRoute: this.mainRoute,
          // gmapAddress: this.currentGmapAddress
        });
      }
      if (this.isNotEmptyMessageVisitAlert(this.messageVisitAlert)) {
        // should check if we can have empty message or not
        this.$store.dispatch("myroute/showMessageVisitAlert");
      }
      this.goToRoutePage();
    },
    async onChangeDate() {
      let resp = await ApiService.post(`worksession/initday/${this.picker}`);
      if (resp?.success === true && resp?.worksessionId) {
        this.dialogWorksessionId = resp.worksessionId;
        this.$refs.dialogSelectedDateRoutePos.showModal(
          this.dialogWorksessionId
        );
      } else {
        logError("Error !");
      }
    },
    async init() {
      this.isLoading = true;
      this.session = null;
      let LOCAL_DATE = moment(new Date())
        .local()
        .format("YYYY-MM-DD");
      let resp = await ApiService.get(
        `worksession/user/${this.user.id}/${LOCAL_DATE}`
      );
      this.isLoading = false;
      this.session = resp.data;
      this.$store.dispatch("myroute/setWorkSession", this.session);
      this.meta = resp.meta;
      this.totalTimeVisitedMiliseconds = resp.totalTimeVisitedMiliseconds;
      this.diffStartEndLocalTimeOfWorksessionLabel =
        resp.diffStartEndLocalTimeOfWorksessionLabel;
      this.timeVisitedStr = resp.timeVisitedStr;
      this.current_km_data = resp.current_km_data;
      this.vehicleTypeList = resp.vehicleTypeList;
      if (this.vehicleTypeList.length > 0) {
        for (const vehicleItem of this.vehicleTypeList) {
          this.vehicleTypes[vehicleItem["value"]] = vehicleItem["label"];
        }
      }
      this.todayExpenseOthers = resp.todayExpenseOthers;
      const { user_route } = resp;
      if (user_route) {
        if (Array.isArray(user_route) && user_route.length > 0) {
          this.mainRoute = user_route[0];
        } else {
          this.mainRoute = null;
        }
      }
    },
    async goToRoutePage() {
      await this.$router.push({
        path: `${this.session.id}/route`
      });
    },
    async goToNextStep(nextStepIndex) {
      console.log("---------- GO NEXT STEP ----------", nextStepIndex);
      if (nextStepIndex === "NEXT_STEP_NOT_VISITING") {
        await this.$refs.dialogReportReasonsForNotVisiting.showModal();
      } else if (nextStepIndex === "NEXT_STEP_CHANGED_VISIT_TYPES") {
        // display dialog - changed_visit_type
        await this.$refs.dialogReportReasonsForChangedVisitTypes.showModal();
      } else if (nextStepIndex === "NEXT_STEP_KILOMETERS_ROUTE") {
        if (this.isBrand) {
          this.goToNextStep("NEXT_STEP_DAILY_SUMMARY_BY_ROUTE");
        } else {
          // display dialog - KM route
          await this.$refs.dialogReportReasonsForKilometersRoute.showModal();
        }
      } else if (nextStepIndex === "NEXT_STEP_DAILY_SUMMARY_BY_ROUTE") {
        if (window.confirm(this.$t("session.confirm_finish_route"))) {
          if (!this.isEndedSession) {
            let endPosition = {
              endLat: null,
              endLng: null
            };
            if (this.currentPosition) {
              endPosition.endLat = this.currentPosition.latitude;
              endPosition.endLng = this.currentPosition.longitude;
            }
            let endLocalTime = moment().format("YYYY-MM-DD HH:mm:ss");
            let logDate = moment(new Date())
              .local()
              .format("YYYY-MM-DD");
            await ApiService.post(`worksession/${this.session.id}/endroute`, {
              endPosition,
              endLocalTime,
              mainRoute: this.mainRoute,
              // gmapAddress: this.currentGmapAddress,
              isStarted: false,
              isPaused: false,
              logDate,
              currentDate: new Date(),
              endableMoment: new Date(logDate + " 23:59:59")
            });
            await this.$store.dispatch(
              "myroute/getCurrentSession",
              this.user.id
            );
          }
        }
        // go to daily summary page
        this.$router.push({
          name: "router_worksession_dailysummarybyroute",
          params: {
            id: this.session.id
          }
        });
      } else if (nextStepIndex === "NEXT_STEP_DAILY_SUMMARY_BY_POS") {
        // go to daily summary page
        this.$router.push({
          name: "router_worksession_dailysummarybypos",
          params: {
            id: this.session.id
          }
        });
      }
    },
    async finishRoute() {
      console.log("---------- FINISH ROUTE ----------");
      if (!this.isEndedSession) {
        this.goToNextStep("NEXT_STEP_NOT_VISITING");
        // this.goToNextStep("NEXT_STEP_KILOMETERS_ROUTE");
      }
    },
    async restartSession() {
      if (this.session) {
        this.isLoadingButtons.RESTART_SESSION = true;
        let LOCAL_DATE = moment(new Date())
          .local()
          .format("YYYY-MM-DD");
        // set isEnded to true
        const { data } = await ApiService.post(`worksession/restart_session`, {
          id: this.session.id,
          localDate: LOCAL_DATE
        });
        this.isLoadingButtons.RESTART_SESSION = false;
        if (data) {
          this.$store.dispatch("myroute/setWorkSession", data);
          // goto routepage
          this.goToRoutePage();
        }
      }
    },
    async pauseAndRestartRoute(isPaused) {
      console.log("---------- PAUSE OR RESTART ROUTE ----------");
      this.isLoadingButtons.PAUSE_AND_RESTART_SESSION = true;
      let localPosition = {
        latitude: null,
        longitude: null
      };
      if (this.currentPosition) {
        localPosition.latitude = this.currentPosition.latitude;
        localPosition.longitude = this.currentPosition.longitude;
      }
      let logDate = moment(new Date())
        .local()
        .format("YYYY-MM-DD");
      let formData = {
        localPosition,
        mainRoute: this.mainRoute,
        // gmapAddress: this.currentGmapAddress,
        isPaused,
        worksessionId: this.session.id,
        isStarted: true,
        logDate,
        currentDate: new Date(),
        endableMoment: new Date(logDate + " 23:59:59")
      };
      await ApiService.post(`worksession/pause_or_restart_route`, formData);
      await this.init();
      this.isLoadingButtons.PAUSE_AND_RESTART_SESSION = false;
    },
    async goToRoute() {
      console.log("userLiquidationType - ", this.userLiquidationType);
      if (this.isBrand) {
        if (!this.current_km_data) {
          await this.$refs.dialogStartKM.saveForEmptyKM();
        }
        this.savingStartKM();
      } else {
        if (!this.current_km_data && this.userLiquidationType === "TOTAL_KM") {
          this.showStartKMDialog = true;
        } else {
          this.savingStartKM();
        }
      }
    },
    async onClickDownloadPDFDailySummary() {
      if (this.session) {
        let worksessionId = this.session?.id;
        this.isLoadingButtons.DOWNLOAD_PDF = true;
        this.downloadPDFDailySummary(worksessionId, error => {
          if (error) {
            console.log("ERROR - ", error);
          }
          this.isLoadingButtons.DOWNLOAD_PDF = false;
        });
      }
    },
    async downloadPDFDailySummary(worksessionId, callback) {
      if (worksessionId) {
        try {
          const response = await ApiService.get(
            `worksession/downloadpdfdailysummary/${worksessionId}`,
            {
              responseType: "blob"
            }
          );
          download(response, "dailysummary.pdf");
          callback(null);
        } catch (error) {
          callback(error);
        }
      }
    }
  },

  async mounted() {
    await this.init();
    const specificBox = document.querySelector(".dailysummarybox");
    this.dailysummaryboxHeight = specificBox ? specificBox.clientHeight : 200;
  }
};
</script>

<style>
.btn_control_route {
  width: 200px;
  height: 50px;
}
span.expense_item_value {
  color: #22baf2;
  font-weight: 600;
}
</style>
