<template>
  <div>
    <modal
      name="modal-selected-date-routepos"
      :width="`90%`"
      :height="`auto`"
      scrollable
    >
      <v-form ref="form" lazy-validation>
        <v-card>
          <v-card-text class="d-flex align-center justify-content-start">
            <span class="text-h5">Rutero - {{ selectedDate }}</span>
            <a
              class="d-flex justify-content-center align-center ml-4"
              v-if="session && !isFutureDate"
              @click="onClickDownloadPDFDailySummary()"
              :disabled="isLoadingButtonDownloadPDF"
            >
              <b-spinner v-if="isLoadingButtonDownloadPDF" medium></b-spinner>
              <template v-else>
                <img
                  class=""
                  src="@/assets/download-pdf-icon.png"
                  alt=""
                  height="50"
                />
              </template>
            </a>
          </v-card-text>

          <v-card-text>
            <b-alert v-if="isLoadingList" show variant="info">
              <b-icon
                icon="arrow-counterclockwise"
                animation="spin-reverse"
              ></b-icon>
              cargando datos...
            </b-alert>
            <routePosList
              v-else-if="filteredPosList && filteredPosList.length > 0"
              :filteredPosList="filteredPosList"
              :visitTypes="visitTypes"
              :isOnlyVisible="true"
              :onPosClicked="onPosClicked"
              :onClickRemoveAdditionalItem="onClickRemoveAdditionalItem"
            ></routePosList>
            <b-alert v-else show variant="danger">
              <b-icon icon="exclamation-triangle-fill"></b-icon>
              {{ $t("Empty data") }}...
            </b-alert>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click.stop="hideModal()">
              {{ $t("cancel") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </modal>
  </div>
</template>

<script>
import ApiService from "@/services/apiService";
import { logInfo, logError } from "@/utils";
import _ from "lodash";
import moment from "moment";
import routePosList from "./routePosList.vue";

export default {
  name: "DialogSelectedDateRoutePos",
  props: ["routeDate", "downloadPDFDailySummary"],
  components: {
    routePosList
  },
  data: function() {
    return {
      selectedDate: new Date().toISOString().slice(),
      sessionId: null,
      session: null,
      meta: null,
      filteredPosList: null,
      visitTypes: [],
      isLoadingButtonDownloadPDF: false,
      isLoadingList: false
    };
  },
  watch: {},
  computed: {
    isFutureDate() {
      let ret = false;
      const currentDate = moment().format("YYYY-MM-DD");
      if (currentDate < this.routeDate) {
        ret = true;
      }
      return ret;
    }
  },
  methods: {
    showModal(sessionId) {
      this.sessionId = sessionId;
      this.filteredPosList = [];
      this.session = null;
      this.isLoadingList = true;
      this.init();
      this.$modal.show("modal-selected-date-routepos");
    },
    hideModal() {
      this.session = null;
      this.$modal.hide("modal-selected-date-routepos");
    },
    async onPosClicked(pos) {
      console.log(pos);
      const { routeId, posId, visitType, brand_list } = pos;
      const worksessionId = this.sessionId;
      const formdata = { routeId, posId, visitType, worksessionId, brand_list };
      let { data } = await ApiService.post(
        `worksession/posinitialize`,
        formdata
      );
      if (data) {
        console.log(data);
        if (data.visitType === "AGENDA") {
          this.$router.push({
            name: "router_worksessionschedulepos",
            params: {
              id: this.sessionId,
              worksessionPosId: data.id
            }
          });
        } else {
          this.$router.push({
            name: "router_worksessionpos",
            params: {
              id: this.sessionId,
              worksessionPosId: data.id
            }
          });
        }
      }
    },
    async onClickRemoveAdditionalItem(additionalIds) {
      console.log(additionalIds);
      if (additionalIds.length > 0 && confirm(this.$t("confirmdelete"))) {
        let fetch_url = "worksession/removeadditionalitems";
        let formdata = {
          additionalIds
        };
        let response = await ApiService.post(fetch_url, formdata);
        if (response.success) {
          await this.init();
        }
      }
    },
    async onClickDownloadPDFDailySummary() {
      if (this.session) {
        let worksessionId = this.session?.id;
        this.isLoadingButtonDownloadPDF = true;
        this.downloadPDFDailySummary(worksessionId, error => {
          if (error) {
            console.log("ERROR - ", error);
          }
          this.isLoadingButtonDownloadPDF = false;
        });
      }
    },
    async init() {
      if (this.routeDate) {
        this.selectedDate = moment(this.routeDate).format("DD/MM/YYYY");
        console.log(this.sessionId);
        let fetch_url = `worksession/${this.sessionId}`;
        const { data, meta, visitTypes, user_route } = await ApiService.get(
          fetch_url
        );
        this.visitTypes = visitTypes;
        this.session = data;
        this.meta = meta;
        //let's join all data
        const { routePos, additionalPOSdata, mergedPosData } = this.meta;
        this.filteredPosList = mergedPosData
          .map(el => {
            let item = { ...el };
            const { isVisited, statusValue } = item.status;
            if (!this.isFutureDate) {
              if (!isVisited || statusValue === "PENDING") return null;
            } else {
              if (isVisited && statusValue !== "PENDING") return null;
            }
            if (item.status.scheduleDateTime) {
              item.status.originalScheduleDateTime = moment(
                item.status.originalScheduleDateTime
              )
                .local()
                .format("DD/MM/YYYY HH:mm");
            }
            return item;
          })
          .filter(el => el);
      }
      this.isLoadingList = false;
    }
  },
  async updated() {},
  async mounted() {}
};
</script>

<style></style>
