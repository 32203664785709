<template>
  <div>
    <modal
      name="modal-reporting-reasons-notvisiting"
      :width="`90%`"
      :height="`auto`"
      scrollable
    >
      <v-card>
        <v-card-text class="objective_content">
          <span class="text-h5">POS NO Visitados</span>
        </v-card-text>

        <v-card-text>
          <v-form ref="formReasonsNotVisiting" v-model="valid" lazy-validation>
            <b-alert show variant="info" v-if="isLoading">
              <b-icon
                icon="arrow-counterclockwise"
                animation="spin-reverse"
              ></b-icon>
              cargando datos...
            </b-alert>
            <b-alert show variant="warning" v-else-if="tableData.length === 0">
              ¡Datos vacíos!...
            </b-alert>
            <v-data-table
              v-else
              :headers="columns"
              :items="tableData"
              :options.sync="options"
              class="elevation-1"
              :hide-default-footer="true"
              disable-pagination
            >
              <template
                v-for="(col, i) in filters"
                v-slot:[`header.${i}`]="{ header }"
              >
                <div
                  style="display: inline-block; padding: 16px 0;"
                  v-bind:key="i"
                >
                  <span>{{ header.text }}</span>
                </div>
                <div style="float: right; margin-top: 8px" v-bind:key="`A` + i">
                  <v-menu
                    :close-on-content-click="false"
                    :nudge-width="200"
                    offset-y
                    transition="slide-y-transition"
                    left
                    fixed
                    style="position: absolute; right: 0"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="indigo" icon v-bind="attrs" v-on="on">
                        <v-icon
                          small
                          :color="
                            activeFilters[header.value] &&
                            activeFilters[header.value].length <
                              filters[header.value].length
                              ? 'red'
                              : 'default'
                          "
                        >
                          mdi-filter-variant
                        </v-icon>
                      </v-btn>
                    </template>
                    <v-list flat dense class="pa-0">
                      <v-list-item-group
                        multiple
                        v-model="activeFilters[header.value]"
                        class="py-2"
                      >
                        <div class="search_input">
                          <v-text-field
                            hide-details
                            v-model="searchString[header.value]"
                            placeholder="Search ..."
                            class="pl-2 pr-2"
                            @input="searchItem(header.value)"
                          ></v-text-field>
                        </div>
                        <template v-for="(item, j) in filters[header.value]">
                          <v-list-item
                            v-bind:key="`A${j}`"
                            :value="item"
                            :ripple="false"
                          >
                            <template v-slot:default="{ active, toggle }">
                              <v-list-item-action>
                                <v-checkbox
                                  :input-value="active"
                                  :true-value="item"
                                  @click="toggle"
                                  color="primary"
                                  :ripple="false"
                                  dense
                                ></v-checkbox>
                              </v-list-item-action>
                              <v-list-item-content>
                                <v-list-item-title
                                  v-text="item"
                                ></v-list-item-title>
                              </v-list-item-content>
                            </template>
                          </v-list-item>
                        </template>
                      </v-list-item-group>
                      <v-row no-gutters>
                        <v-col cols="6">
                          <v-btn
                            text
                            block
                            @click="toggleAll(header.value)"
                            color="success"
                            >{{ $t("toggleall") }}</v-btn
                          >
                        </v-col>
                        <v-col cols="6">
                          <v-btn
                            text
                            block
                            @click="clearAll(header.value)"
                            color="warning"
                            >{{ $t("clearall") }}</v-btn
                          >
                        </v-col>
                      </v-row>
                    </v-list>
                  </v-menu>
                </div>
              </template>
              <template v-slot:item.reasonTypeLabel="{ item }">
                <v-autocomplete
                  v-model="item.reasonType"
                  :items="reasonNotVisitingTypes"
                  item-text="label"
                  item-value="value"
                  filled
                  dense
                  required
                  :rules="requiredRules"
                  class="mt-2"
                ></v-autocomplete>
              </template>
              <template v-slot:item.reasonComments="{ item }">
                <v-text-field
                  v-model="item.reasonComments"
                  dense
                  required
                  :rules="requiredRules"
                  class="mt-2"
                ></v-text-field>
              </template>
              <template v-slot:item.recover="{ item }">
                <v-btn
                  v-if="!item?.additionalpos || item.additionalpos.length === 0"
                  color="blue"
                  @click="recoverVisit(item)"
                >
                  {{ $t("recover") }}
                </v-btn>
                <div
                  v-else-if="
                    item?.additionalpos && item.additionalpos.length > 0
                  "
                >
                  <div
                    v-for="(addedItem, additemkey) in item.additionalpos"
                    :key="`add_pos_item_${additemkey}`"
                  >
                    <span>
                      {{ moment(addedItem.date).format("DD/MM/YYYY") }}
                      /
                      {{ visitTypes[addedItem.visitType] }}
                    </span>

                    <v-btn
                      color="red darken-1"
                      text
                      @click="removeAdditionalPos(item, addedItem, additemkey)"
                    >
                      <b-icon icon="x-lg" aria-hidden="true"></b-icon>
                    </v-btn>
                  </div>
                </div>
              </template>
            </v-data-table>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click.stop="hideModal()">
            {{ $t("cancel") }}
          </v-btn>
          <v-btn color="primary" @click="onSaveClick" :loading="isSaving">
            {{ this.tableData.length > 0 ? $t("save") : $t("Next") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </modal>
    <ConfirmDialogPosPageAddClient
      v-if="selectedRoutePosItem"
      ref="confirmDialogPosPageAddClient"
      :visitTypes="visitTypes"
      :routeId="selectedRoutePosItem.routeId"
      :posId="selectedRoutePosItem.posId"
      :selectableBrands="selectedRoutePosItem.brand_list"
      :confirmSelectionCriteria="confirmSelectionCriteria"
    />
  </div>
</template>

<script>
import ApiService from "@/services/apiService";
import { logInfo, logError } from "@/utils";
import _ from "lodash";
import moment from "moment";
import ConfirmDialogPosPageAddClient from "../ConfirmDialogPosPageAddClient.vue";

export default {
  name: "DialogReportReasonsForNotVisiting",
  props: ["session", "meta", "mainRoute"],
  components: {
    ConfirmDialogPosPageAddClient
  },
  data: function() {
    return {
      isLoading: false,
      isSaving: false,
      today: new Date().toISOString().slice(0, 10),
      reasonNotVisitingTypes: [],
      reasons_not_visiting: [],

      options: {},
      tempdata: [],
      tableData: [],
      activeFilters: {},
      filters: {},
      initFilterList: {},
      searchString: {},
      valid: true,
      requiredRules: [v => !!v || "Required"],
      selectedRoutePosItem: null,
      visitTypes: [],
      editingRecoverItem: null
    };
  },
  watch: {
    options: {
      handler() {
        this.sortChange();
      },
      deep: true
    }
  },
  computed: {
    columns() {
      return [
        {
          text: `POS ${this.$t("brands.name")}`,
          value: "posName",
          width: "250px",
          filter: value => {
            return this.activeFilters.posName
              ? this.activeFilters.posName.includes(value)
              : true;
          }
        },
        {
          text: `${this.$t("chain")} ${this.$t("brands.name")}`,
          value: "chainName",
          width: "250px",
          filter: value => {
            return this.activeFilters.chainName
              ? this.activeFilters.chainName.includes(value)
              : true;
          }
        },
        {
          text: `POS ${this.$t("address")}`,
          value: "posFullAddress",
          width: "300px",
          filter: value => {
            return this.activeFilters.posFullAddress
              ? this.activeFilters.posFullAddress.includes(value)
              : true;
          }
        },
        {
          text: this.$t("Reason for not visiting"),
          value: "reasonTypeLabel",
          width: "200px",
          sortable: false
        },
        {
          text: this.$t("comments"),
          value: "reasonComments",
          width: "200px",
          sortable: false
        },
        {
          text: this.$t("recover"),
          value: "recover",
          width: "200px",
          sortable: false
        }
      ];
    }
  },
  methods: {
    moment,
    recoverVisit(item) {
      console.log("item - ", item);
      this.editingRecoverItem = item;
      this.selectedRoutePosItem = null;
      const { routeId, posId, visitType, changedVisitType } = item;
      if (this.meta && this.meta?.mergedPosData) {
        this.selectedRoutePosItem = this.meta.mergedPosData.find(
          el =>
            el.routeId === routeId &&
            el.posId === posId &&
            el.visitType === visitType &&
            el.changedVisitType === changedVisitType
        );
        if (this.selectedRoutePosItem) {
          this.$nextTick(() => {
            this.$refs.confirmDialogPosPageAddClient.showModal();
          });
        }
      }
    },
    async confirmSelectionCriteria(addCredential) {
      console.log("addCredential - ", addCredential);
      if (!addCredential) return;
      let fetch_url = "worksession/addcurrentvisit";
      const { routeId, posId } = this.selectedRoutePosItem;
      let formdata = {
        addCredential,
        routeId,
        posId
      };
      let index = this.tableData.indexOf(this.editingRecoverItem);
      if (index > -1) {
        this.tableData[index].additionalpos.push(addCredential);
      }
    },
    async removeAdditionalPos(item, addedItem, additemkey) {
      let index = this.tableData.indexOf(item);
      if (index > -1) {
        this.tableData[index].additionalpos.splice(additemkey, 1);
      }
    },
    async showModal() {
      this.isLoading = true;
      this.$modal.show("modal-reporting-reasons-notvisiting");
      await this.init();
    },
    hideModal() {
      this.$modal.hide("modal-reporting-reasons-notvisiting");
    },
    initFilters() {
      let keys = Object.keys(this.initFilterList);
      for (const key of keys) {
        this.searchString[key] = "";
        this.initFilterList[key] = this.tableData
          .map(d => {
            return d[key];
          })
          .filter((value, index, self) => {
            return self.indexOf(value) === index;
          });
      }
      this.filters = Object.assign({}, this.initFilterList);
      this.activeFilters = Object.assign({}, this.filters);
    },
    searchItem(col) {
      this.changeStr(col, this.searchString[col]);
    },
    changeStr(col, search_str) {
      if (search_str) {
        this.filters[col] = this.initFilterList[col].filter(item =>
          item.toLowerCase().includes(search_str.toLowerCase())
        );
      } else {
        this.filters[col] = this.initFilterList[col];
      }
      this.activeFilters = Object.assign({}, this.filters);
    },
    toggleAll(col) {
      this.activeFilters[col] = this.tableData
        .map(d => {
          return d[col];
        })
        .filter((value, index, self) => {
          return self.indexOf(value) === index;
        });
      this.searchString[col] = null;
      this.changeStr(col, null);
    },
    clearAll(col) {
      this.activeFilters[col] = [];
    },
    sortChange() {
      let { sortBy, sortDesc } = this.options;
      if (sortBy && sortBy.length > 0) {
        sortBy = sortBy[0];
        sortDesc = sortDesc[0];
        if (sortDesc) {
          this.tempdata = _.sortBy(this.tempdata, sortBy).reverse();
        } else {
          this.tempdata = _.sortBy(this.tempdata, sortBy);
        }
        this.tableData = this.tempdata;
      }
    },
    async onSaveClick() {
      if (!this.$refs.formReasonsNotVisiting.validate()) {
        logError("You should answer all rows.");
        return;
      }
      if (this.tableData.length > 0) {
        for (const row of this.tableData) {
          if (!row.reasonType) {
            logError("You should answer all rows.");
            return;
          }
        }
        this.isSaving = true;
        // Save answers
        let formData = {
          reasons_not_visiting: this.tableData
        };
        const response = await ApiService.post(
          `worksession/report_save_not_visitings`,
          formData
        );
        this.isSaving = false;
        if (response && response?.success) {
          logInfo(this.$t("Success"));
        } else {
          logError(this.$t("Failed"));
          return;
        }
      }
      this.goNextStepPage();
    },
    goNextStepPage() {
      this.hideModal();
      // go to next step
      this.$emit("goToNextStep", "NEXT_STEP_CHANGED_VISIT_TYPES");
    },
    initializeTableData(tbData) {
      this.tableData = tbData;
      this.tempdata = tbData;
      this.initFilters();
    },
    async init() {
      let initFilterCriteria = {
        posName: [],
        chainName: [],
        posFullAddress: []
      };
      this.filters = JSON.parse(JSON.stringify(initFilterCriteria));
      this.initFilterList = JSON.parse(JSON.stringify(initFilterCriteria));
      this.searchString = JSON.parse(JSON.stringify(initFilterCriteria));
      const { id } = this.session;
      let { mergedPosData } = this.meta;
      this.isSaving = false;
      this.selectedRoutePosItem = null;
      if (id) {
        if (mergedPosData && mergedPosData.length > 0) {
          mergedPosData = mergedPosData
            .filter(
              el =>
                el.isAdditional === false &&
                el?.status &&
                el.status.isEnded !== true
            )
            .map(el => {
              let {
                id,
                routeId,
                posId,
                visitType,
                changedVisitType,
                brand_list,
                status
              } = el;
              return {
                routeId,
                posId,
                visitType,
                changedVisitType,
                brand_list,
                status
              };
            });
        } else {
          mergedPosData = [];
        }
        let form_data = {
          worksessionId: id,
          mergedPosData,
          today: this.today
        };
        const {
          reasonNotVisitingTypes,
          reasons_not_visiting,
          visitTypes
        } = await ApiService.post(
          `worksession/report_init_not_visitings`,
          form_data
        );
        this.reasonNotVisitingTypes = reasonNotVisitingTypes;
        this.reasons_not_visiting = reasons_not_visiting;
        this.visitTypes = visitTypes;
        if (this.reasons_not_visiting.length > 0) {
          this.isLoading = false;
          this.initializeTableData(this.reasons_not_visiting);
        } else {
          this.goNextStepPage();
        }
      }
    }
  },
  async updated() {},
  async mounted() {}
};
</script>

<style></style>
