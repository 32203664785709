<template>
  <div>
    <modal
      name="modal-reporting-reasons-km-routes"
      :width="`90%`"
      :height="`auto`"
      scrollable
    >
      <v-card>
        <v-card-text class="objective_content">
          <span class="text-h5">{{ $t("Kilometers") }} {{ $t("route") }}</span>
        </v-card-text>

        <v-card-text>
          <v-form ref="formReasonsKMRoutes" v-model="valid" lazy-validation>
            <b-alert show variant="info" v-if="isLoading">
              <b-icon
                icon="arrow-counterclockwise"
                animation="spin-reverse"
              ></b-icon>
              cargando datos...
            </b-alert>
            <b-alert show variant="warning" v-else-if="tableData.length === 0">
              ¡Datos vacíos!...
            </b-alert>
            <v-data-table
              v-else
              :headers="columns"
              :items="tableData"
              class="elevation-1"
              :hide-default-footer="true"
            >
              <template v-slot:item.finalKMByGPV="{ item }">
                <v-text-field
                  type="number"
                  v-model="item.finalKMByGPV"
                  required
                  :rules="calcDifferenceStartEndKM < 0 ? [kmTotalNumRules] : []"
                ></v-text-field>
              </template>
              <template v-slot:item.gpvComments="{ item }">
                <v-text-field
                  v-model="item.gpvComments"
                  required
                  :rules="requiredRules"
                ></v-text-field>
              </template>
              <template v-slot:item.startKM="{}">
                <span>{{ current_km_data?.startKM }}</span>
              </template>
              <template v-slot:item.differenceStartEndKM="{}">
                <span>{{ formattedCalcDifferenceStartEndKM }}</span>
              </template>
            </v-data-table>
            <v-row v-if="current_km_data" class="mt-2">
              <v-col cols="12" sm="12" md="12" class="text-center">
                <v-row>
                  <v-col cols="12" sm="12" class="text-center">
                    <gennera-picture-input
                      :crop="false"
                      class="my-picture-input"
                      ref="pictureInputEndPhoto"
                      width="500"
                      height="300"
                      size="5"
                      :prefill="
                        getImageUri(
                          current_km_data.endPhoto.static
                            ? current_km_data.endPhoto.static
                            : null
                        )
                      "
                      accept="image/jpeg,image/png"
                      button-class="btn btn-outline-info"
                      aspect="Landscape"
                      :custom-strings="{
                        upload: this.$t('uploading_image'),
                        change: this.$t('change'),
                        tap: 'toma una foto'
                      }"
                      @change="onPictureEndPhoto"
                      :capture="`camera`"
                    >
                    </gennera-picture-input>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click.stop="hideModal()">
            {{ $t("cancel") }}
          </v-btn>
          <v-btn color="primary" @click="onSaveClick" :loading="isSaving">
            {{ this.tableData.length > 0 ? $t("save") : $t("Next") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </modal>
  </div>
</template>

<script>
import ApiService from "@/services/apiService";
import { logInfo, logError } from "@/utils";
import _ from "lodash";
import moment from "moment";
import PictureInput from "vue-picture-input";
import { mapGetters } from "vuex";
import GenneraPictureInput from "@/components/GenneraPictureInput.vue";

export default {
  name: "DialogReportReasonsForKilometersRoute",
  props: ["session", "meta", "mainRoute"],
  components: {
    PictureInput,
    GenneraPictureInput
  },
  data: function() {
    return {
      isLoading: false,
      isSaving: false,
      today: new Date().toISOString().slice(0, 10),
      reasonChangedVisitTypes: [],
      reasons_kilometers_route: [],

      tableData: [],
      valid: true,
      requiredRules: [v => !!v || "Required"],
      kmTotalNumRules: v => {
        if (!isNaN(parseFloat(v)) && v >= 0) return true;
        return "Los KM de inicio no pueden ser mayores que los KM final";
      },

      selected: null,
      current_km_data: null,
      isSelecting: false,
      defaultButtonText: "Select ..."
    };
  },
  watch: {},
  computed: {
    ...mapGetters("myroute", ["deviceInformation"]),
    buttonText() {
      return this.isSelecting ? "Uploading..." : this.defaultButtonText;
    },
    calcDifferenceStartEndKM() {
      let ret = 0;
      if (this.current_km_data && this.tableData.length > 0) {
        const { startKM } = this.current_km_data;
        const currentRecord = this.tableData[0];
        const { finalKMByGPV } = currentRecord;
        if (finalKMByGPV && !isNaN(finalKMByGPV - startKM)) {
          ret = finalKMByGPV - startKM;
        }
      }
      return ret;
    },
    formattedCalcDifferenceStartEndKM() {
      return this.numberFormatEs(this.calcDifferenceStartEndKM);
    },
    columns() {
      return [
        {
          text: `Total KM GPS`,
          value: "realTotalKMFormated",
          sortable: false,
          width: "200px"
        },
        {
          text: `TOTAL KM ${this.$t("Optimized")}`,
          value: "optimizedTotalKMFormated",
          sortable: false
        },
        {
          text: `${this.$t("Difference")}`,
          value: "differenceFormated",
          sortable: false
        },
        {
          text: `${this.$t("Start KM")}`,
          value: "startKM",
          sortable: false
        },
        {
          text: `${this.$t("End KM")}`,
          value: "finalKMByGPV",
          sortable: false
        },
        {
          text: `${this.$t("Difference")}`,
          value: "differenceStartEndKM",
          sortable: false
        },
        {
          text: this.$t("comments"),
          value: "gpvComments",
          sortable: false,
          width: "200px"
        }
      ];
    }
  },
  methods: {
    getImageUri(imgStatic) {
      if (imgStatic && imgStatic?.type === "file") {
        return ApiService.getFileUri(imgStatic);
      }
      return ApiService.getImageUri(imgStatic);
    },
    showModal() {
      this.isLoading = true;
      this.init();
      this.$modal.show("modal-reporting-reasons-km-routes");
    },
    hideModal() {
      this.$modal.hide("modal-reporting-reasons-km-routes");
    },
    async onSaveClick() {
      if (this.tableData.length > 0) {
        let formData = {
          reasons_kilometers_route: this.tableData
        };
        const { finalKMByGPV } = formData.reasons_kilometers_route[0];
        if (this.current_km_data) {
          const { startKM } = this.current_km_data;
          if (startKM > finalKMByGPV) {
            logError(
              "Los KM de inicio no pueden ser mayores que los KM final."
            );
            return;
          }
        }
        if (!this.$refs.formReasonsKMRoutes.validate()) {
          return;
        }
        this.isSaving = true;
        // Save answers
        let body = _.omit(this.current_km_data, ["endPhoto"]);
        let imgEnd = this.current_km_data.endPhoto.img;
        if (imgEnd) {
          let { data } = await ApiService.uploadImage(imgEnd);
          body = {
            ...body,
            endPhotoId: data.id
          };
        } else if (!this.current_km_data.endPhoto.static) {
          logError("Se requiere una foto final.");
          this.isSaving = false;
          return;
        }
        formData = { ...formData, current_km_data: body };
        const response = await ApiService.post(
          `worksession/report_save_difference_km_route`,
          formData
        );
        this.isSaving = false;
        if (response && response?.success) {
          logInfo(this.$t("Success"));
        } else {
          logError(this.$t("Failed"));
          return;
        }
      }
      // Save answers
      this.hideModal();
      // go to next step
      this.$emit("goToNextStep", "NEXT_STEP_DAILY_SUMMARY_BY_ROUTE");
    },
    onPictureEndPhoto(img) {
      this.current_km_data.endPhoto.img = this.$refs[
        "pictureInputEndPhoto"
      ].file;
    },
    async init() {
      const { id } = this.session;
      this.isSaving = false;
      if (id) {
        let form_data = {
          worksessionId: id
        };
        const {
          reasons_kilometers_route,
          current_km_data
        } = await ApiService.post(
          `worksession/report_init_difference_km_route`,
          form_data
        );
        this.reasons_kilometers_route = reasons_kilometers_route;
        this.current_km_data = current_km_data;
        this.isLoading = false;
        this.tableData = reasons_kilometers_route;
        this.current_km_data = {
          id: _.get(current_km_data, "id"),
          startKM: _.get(current_km_data, "startKM"),
          endPhoto: {
            img: null,
            static: _.get(current_km_data, "endPhoto")
          }
        };
      }
    }
  },
  async updated() {},
  async mounted() {}
};
</script>

<style></style>
